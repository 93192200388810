<template>
    <v-row
      no-gutters
      class="h-100"
    >
      <v-col cols="12" offset="0" md="4" offset-md="4" class="d-flex flex-column justify-center align-center">
        <div class="mb-12">
          <v-img
            eager
            :transition="false"
            :src="require('@/assets/logo/SPEDIMO_logo.svg')"
            width="250px"
            height="35px"
            class="ma-1"
          ></v-img>
        </div>
        <v-card class="w-100 rounded-lg py-4" outlined flat>
          <v-card-title>
            <h4 class="primary--text">{{ $t('login.title') }}</h4>
          </v-card-title>
          <v-card-text class="px-8">
            <v-form
              ref="loginForm"
              v-model="valid"
            >
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.email"
                    placeholder=" "
                    persistent-placeholder
                    autocomplete
                    :label="$t('login.email')"
                    outlined
                    required
                    dense
                    single-line
                    validate-on-blur
                    :rules="emailRules"
                    @keyup.enter="submitHandle"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.password"
                    autocomplete
                    placeholder=" "
                    persistent-placeholder
                    :append-icon="form.passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="form.passwordShow ? 'text' : 'password'"
                    name="input-10-1"
                    :label="$t('login.password')"
                    outlined
                    dense
                    single-line
                    validate-on-blur
                    :rules="passwordRules"
                    @keyup.enter="submitHandle"
                    @click:append="form.passwordShow = !form.passwordShow"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-card-actions class="d-flex px-0">
              <v-btn color="primary" min-width="120px" class="ml-auto" :disabled="submitLoading" :loading="submitLoading" @click="submitHandle" depressed>
                {{ $t('login.submit') }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
        <div class="d-flex mt-4">
          <span class="mr-2">
            {{ $t('login.dontRememberPassword') }}
          </span>
          <span class="primary--text font-weight-semibold pointer" @click="$router.push({ name: 'ForgottenPassword' })">{{ $t('login.resetPassword') }}</span>
        </div>
        <div v-if="$route.name === 'Login'" class="d-flex mt-4">
          <span class="mr-2">
            {{ $t('login.doNotHaveAnAccount') }}
          </span>
          <span class="primary--text font-weight-semibold pointer" @click="$router.push({ name: 'Register' })">{{ $t('login.register') }}</span>
        </div>
      </v-col>
     <TwoFactorMethod
        v-model="twoFactorMethodDialog"
        :loginData="form"
        :twoFactorMethods="twoFactorMethods"
        @close="closeTwoFactorMethodDialog()"
      />
      <ConfirmConsentDialog
        :value="confirmConsentDialog"
        :email="form.email"
        :password="form.password"
      />
    </v-row>
</template>

<script>
import services from '@/services'
import { mapActions } from 'vuex'
import * as mutationTypes from '@/store/mutation-types'
import TwoFactorMethod from './dialogs/TwoFactorMethod.vue'
import ConfirmConsentDialog from '@/components/dialogs/ConfirmConsentDialog'

export default {
  name: 'Login',
  components: {
    TwoFactorMethod,
    ConfirmConsentDialog
  },
  data () {
    return {
      form: {
        email: '',
        password: '',
        passwordShow: false
      },
      submitLoading: false,
      valid: true,
      passwordRules: [
        v => !!v.trim() || this.$t('validation.passwordRequired')
      ],
      emailRules: [
        v => !!v.trim() || this.$t('validation.emailRequired'),
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || this.$t('validation.emailInvalidFormat')
      ],
      twoFactorMethodDialog: false,
      twoFactorMethods: {
        phoneNumber: null,
        email: null
      },
      confirmConsentDialog: false
    }
  },
  methods: {
    ...mapActions({
      fetchUserData: 'fetchUserData'
    }),
    async submitHandle () {
      if (!this.$refs.loginForm.validate()) {
        return
      }
      this.submitLoading = true
      try {
        const { data } = await services.post('Authentication/Login', {
          email: this.form.email,
          password: this.form.password,
          deviceId: localStorage.getItem('deviceId') || null,
          isWeb: true
        })
        if (data.resetCode) {
          sessionStorage.setItem('resetCode', data.resetCode)
          sessionStorage.setItem('email', this.form.email)
          await this.$router.push({ name: 'ResetPassword' })
        }

        if (data.twoFactorAuthRequired) {
          this.twoFactorMethods = {
            phoneNumber: data.phoneNumber,
            email: data.email
          }
          this.twoFactorMethodDialog = true
        }

        if (data.token) {
          localStorage.setItem('token', data.token)
          await this.fetchUserData()
          this.$store.commit(mutationTypes.LOGIN, true)
          if (this.$store.getters.isAdmin || this.$store.getters.isCustomerService) {
            await this.$router.push({ name: 'CustomerServiceDashboard' })
          } else {
            await this.$router.push({ name: 'Home' })
          }
        }

        if (!data.requiredAgreementsAccepted) {
          this.confirmConsentDialog = true
        }

        this.$refs.loginForm.resetValidation()
      } catch (e) {}
      this.submitLoading = false
    },
    closeTwoFactorMethodDialog () {
      this.twoFactorMethodDialog = false
    }
  }
}
</script>
