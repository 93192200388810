<template>
  <div>
    <v-dialog v-model="value" width="800" persistent>
      <v-card class="py-4">
        <v-card-title>{{ $t('confirmConsentDialog.title') }}</v-card-title>
        <v-card-text>
          <template
            v-for="agreement in agreements"
          >
            <v-checkbox
              :key="agreement.value"
              v-model="agreement.accepted"
              hide-details
            >
              <template #label>
                <span><span v-if="agreement.required" class="red--text font-weight-bold">*</span>
                  <div class="agreement-header" v-html="agreement.text" @click.stop></div>
              </span>
              </template>
            </v-checkbox>
          </template>
        </v-card-text>
        <v-card-actions class="px-8">
          <v-spacer/>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="primary"
            @click="confirm"
            >{{ $t('common.confirm') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import services from '@/services'
import * as mutationTypes from '@/store/mutation-types'
import { mapActions } from 'vuex'

export default {
  name: 'ConfirmConsentDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      agreements: []
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    ...mapActions({
      fetchUserData: 'fetchUserData'
    }),
    async fetchData () {
      const { data } = await services.get('Authentication/GetAgreements')
      this.agreements = data.map(a => ({
        ...a,
        accepted: false
      }))
    },
    async confirm () {
      const acceptedAgreements = this.agreements
        .filter(a => a.accepted)
        .map(a => a.value)

      const { data } = await services.put('Authentication/AcceptAgreements', {
        email: this.email,
        password: this.password,
        acceptedAgreements
      })
      if (data.token) {
        await this.$router.push({ name: 'AddDevice' })
        localStorage.setItem('token', data.token)
        await this.fetchUserData()
        this.$store.commit(mutationTypes.LOGIN, true)
      }
    }
  }
}
</script>
